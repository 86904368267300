import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { logout } from '../../services/auth';
import TakeOver from '../common/Takeover';

export default class Logout extends Component {
  componentDidMount() {
    logout(navigate.bind(this, '/'));
  }
  render() {
    return (
      <>
        <TakeOver
          onClick={e => {
            e.preventDefault();
          }}
        />
      </>
    );
  }
}
