import React from "react";
import HomePage from "../components/layout/Home";
import LogUserOut from "../components/home/logout";

export default props => {
  return (
    <HomePage>
      <LogUserOut />
    </HomePage>
  );
};
